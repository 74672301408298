/* @import '../node_modules/primeng/resources/primeng.min.css'; */

html {
  font-size: 14px;
}

html,
body {
  height: 100%;
  min-height: 100% !important;
}

/* ELEMENTS */
label {
  font-weight: normal;
  /* display: inline; */
}

/* note: Used in new-client-dialog */
label.table-label {
  padding: 12px 2px 0 2px;
  font-weight: 600;
  font-size: 85%;
}

/* BL-FRONTEND OVERRIDES FOR BOOTSTRAP STYLING */
.bl-core-menu-item > p {
  margin: 0;
  cursor: default;
  font-size: 1rem;
}
.bl-core-radio-button input[type="radio"], .bl-core-radio-button .bl-core-text-description-label {
  margin-top: 0;
  margin-bottom: 0;
}

/* MISC CLASSES */
.fa-larger-size {
  font-size: 1.3em;
}
.fa-sm {
  font-size: 0.9em;
}
.fa-md {
  font-size: 1.2em;
}
.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}
.white-space-normal {
  white-space: normal !important;
}
input.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid,
.form-control-error-border {
  border: 1px solid;
  border-color: #e3342f;
  background-color: #fcebea;
}
.pb-2-important {
  padding-bottom: 0.5rem !important;
}
.bulk-item-container,
.bulk-item-container > ng-component > div {
  display: inline-block;
}
/* .p-dialog.new-client-dialog .p-dialog-footer {
  padding: 0 !important;
} */

/* BUTTON */
.p-button:not(.p-button-danger):not(.p-button-outline):not(.p-column-filter-add-button):not(.p-button-outlined),
.btn-primary {
  background-color: var(--bl-blue-light-600);
  border-color: var(--bl-blue-light-500);
}
.p-button:not(.p-button-danger):not(.p-button-outline):not(.p-column-filter-add-button):not(.p-button-outlined):enabled:hover {
  background-color: #286090;
  border-color: #204d74;
}

/* TABLE */
.p-datatable table {
  width: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: normal;
  color: #2f2f2f;
  font-size: 0.92rem;
  padding: 14px;
  vertical-align: middle;
}
.p-datatable .p-datatable-thead > tr > th:not(:last-child) {
  border: 1px solid #e4e4e4;
  border-width: 0 1px 1px 0;
}

.p-datatable .p-sortable-column.p-highlight {
  font-weight: 600;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--bl-blue-light-600);
  .p-sortable-column-icon {
    color: var(--bl-blue-light-600);
  }
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2f2f2f;
}
.p-sortable-column-icon.pi {
  font-size: 0.85rem;
}
.p-datatable .p-sortable-column .p-icon-wrapper {
  display: inline flex;
}
.p-datatable .p-sortable-column .p-icon-wrapper svg {
  height: 0.85rem;
  margin-left: 7px;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #d4d4d4;
  border-right: none;
}
.p-datatable .p-datatable-tbody > tr > td > a:not(:hover) {
  @apply text-black;
} 
.p-datatable .p-datatable-tbody > tr > td:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
  border-top: none;
  border-left: none;
}
.p-datatable-scrollable .p-datatable-tbody > tr > td:first-child {
  border-left: 1px solid #d4d4d4;
}
.p-datatable-scrollable .p-datatable-tbody > tr > td[colspan] {
  border-right: 1px solid #d4d4d4;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem 1rem;
  border: 1px solid #e4e4e4;
  border-width: 1px 0 1px 1px;
}

.p-datatable .p-datatable-footer {
  text-align: center;
}

/* table footer - export button */
.p-datatable .p-datatable-footer .export-button-wrapper {
  top: -5px;
  left: 0;
  z-index: 100;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--bl-blue-light-200);
}

.p-dropdown.table-inline-dropdown {
  font-size: small;
  margin: 0px;
  padding: 0px;
  height: 26px;
}
.table-inline-dropdown > .p-dropdown-label {
  padding: 3px 1px 3px 4px;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
  background-color: var(--bl-blue-light-600);
  color: white;
}

/* table - column filter */
.p-column-filter-constraint:not(:first-child)::before {
  content: 'Eller';
  margin-bottom: 10px;
  display: block;
  background-color: white;
}

/* DROPDOWN / MULTISELECT DROPDOWN */
.p-dropdown-label {
  font-family: 'Poppins', sans-serif;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.2rem 1rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--bl-blue-light-200);
}

/* CHECKBOX / RADIOBUTTON */
.p-checkbox-label {
  margin-bottom: 0;
}
.p-checkbox.p-checkbox-disabled {
  cursor: not-allowed;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--bl-blue-light-600);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background-color: #286090;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--bl-blue-light-600);
  background-color: var(--bl-blue-light-600);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #286090;
}
/* */
.p-multiselect-with-all-selection .p-multiselect-header {
  flex-direction: row-reverse;
}
.p-multiselect-with-all-selection .p-multiselect-close {
  display: none;
}

/* DIALOG */
.p-dialog {
  background-color: white;
}

:root {
  --maskbg: rgba(0, 0, 0, 0.1);
}

/* TOOLTIP */
.tooltip-extended-width {
  max-width: 18rem;
}

.ptl-tooltip .p-tooltip-text {
  width: 350px;
  white-space: normal;
}

.subscription-type-tooltip .p-tooltip-text {
  width: 350px;
  white-space: normal;
}

.p-divider.p-divider-horizontal {
  margin: 0;
}

/* P-MENU */
.p-menu.p-menu-overlay {
  min-width: 175px;
  width: auto;
  padding: 0;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  border-radius: 3px;
}

.menuDropdownButton:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.menuDropdownButton:focus {
  box-shadow: none !important;
}

.p-menu .p-menu-separator {
  margin: 0px;
}
/* ---- */ 

.p-inputgroup {
  height: 34px;
}

.corporate-identity-field {
  height: 34px;
  width: 100% !important;
}

.team-selector-standalone {
  margin: 0;
}

#showNotificationsCount,
#advice,
#cleared {
  .p-inputswitch {
    height: 14px;
  }
  .p-inputswitch-slider:before {
    width: 20px;
    height: 20px;
    margin-top: -0.725rem;
    background-color: #424242;
    border-color: rgba(0, 0, 0, 0.87);
    border-radius: 10px;
    left: 0;
  }
  .p-inputswitch-checked .p-inputswitch-slider {
    background-color: #8abfd2;
  }
  .p-inputswitch-checked .p-inputswitch-slider:before {
    width: 20px;
    height: 20px;
    margin-top: -0.725rem;
    background-color: #509bb8;
    border-color: #424242;
    border-radius: 10px;
    left: 0.5rem;
  }
}

#last-updated {
  .p-checkbox-box, p-checkbox-icon {
    width: 15px;
    height: 15px;
  }

  .p-checkbox .p-checkbox-box.p-highlight{
    border-color: #509bb8;
    background: #fff;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #509bb8;
    font-size: 10px;
  }

  .p-scrollpanel-bar, .p-scrollpanel-bar-x, .p-scrollpanel-bar-y {
    @apply bg-bl-blue-light-300;
  }
}
p-dropdown.ng-invalid>.p-dropdown {
  border-color: #f44336;
}
input.ng-invalid.form-control {
  border-color: #f44336;
}
calendaricon {
  color: white;
}
button.p-button-sm {
  font-size: .875rem;
  padding: 0.4375rem 0.875rem;
}
.p-multiselect-items-wrapper >ul,
.p-dropdown-items-wrapper > ul {
  @apply mb-0;
}
.p-inputgroup-addon {
  min-width: fit-content;
}
#partnerprogram-container ul.p-tabview-nav{
  border-color: transparent;
  background-color: inherit;
}

#partnerprogram-container ul.p-tabview-nav li:not(.p-highlight) a {
  border-color: transparent;
  background-color: inherit;
  color: var(--bl-grey-500);
  @apply font-medium;
}

#partnerprogram-container .p-tabview .p-tabview-panels {
  background-color: inherit;
}

#partnerprogram-container ul.p-tabview-nav li.p-highlight a.p-tabview-nav-link {
  border-color: var(--bl-blue-light-500);
  background-color: inherit;
  color: var(--bl-grey-900);
  @apply font-medium;
}

.partner-level-card {
  width: 336px;
  height: 210px;
}

.partner-level-one {
  @apply text-bl-orange-300;
}

.partner-level-two {
  @apply text-bl-grey-700;
}

.partner-level-three {
  @apply text-bl-orange-300;
}

.partner-level-four {
  @apply text-bl-grey-700;
}

.p-toast {
  width: auto;
}